// Bootstrap JS and CSS
import 'bootstrap';
import './../css/main.scss';

// Bootstrap Popover
import './vendor/popover';

// Bootstrap Tooltip
import './vendor/tooltip';

$("a.is-active").parent('li').css("display", "none");

$(".en-crs").parent().addClass("en-header");
$(".fr-crs").parent().addClass("fr-header");

$(".en-crs-detail").parent().addClass("en-detail-header");
$(".fr-crs-detail").parent().addClass("fr-detail-header");

$(".position-description").parent().find(".nav-right .c-lggnav").hide();
$(".position-description").parent().find(".lang-mbl .c-lggnav").hide();

$(document).ready(function(){

    // -------------------Navigation Bar code-------------------
    $("#menu").on("click", function(){
        $("#menu").css("opacity", "0");
        $("#lgMenu").addClass("enter");
        $(".full-overlay").fadeIn(200);
    });
    $("#exit, .full-overlay").on("click", function(){
        $("#lgMenu").removeClass("enter");
        $("#menu").css("opacity", "1");
        $(".full-overlay").fadeOut(200);
    });

});

// -------------------Fixed Navbar -------------------
$(window).scroll(function(){
    if ($(window).scrollTop() >= 230) {
        $('header').addClass('sticky');
        $('header').addClass('.fadeInDown');
    } else {
        $('header').removeClass('sticky');
        $('header').removeClass('.fadeInDown');
    }
});

// -------------------Benefits Slider all configurations-------------------
$("#benef-slider").owlCarousel({
    autoplay: true,
    lazyLoad: true,
    loop: false,
    startPosition: 1,
    margin: 20,
    responsiveClass: true,
    autoHeight: true,
    autoplayTimeout: 7000,
    smartSpeed: 800,
    nav: true,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        1024: {
            items: 3
        },
        1366: {
            items: 4
        }
    }
});

// -------------------Benefits Slider Next and Previous Arrow Icon-------------------
$( ".owl-prev").html('<i class="fa fa-long-arrow-left"></i> ');
$( ".owl-next").html('<i class="fa fa-long-arrow-right"></i> ');

// ------------------------------------------------------------------------------------
function DropDown(el) {
    this.dd = el;
    this.placeholder = this.dd.children('span');
    this.opts = this.dd.find('ul.drop li');
    this.val = '';
    this.index = -1;
    this.initEvents();
}

DropDown.prototype = {
    initEvents: function () {
        var obj = this;
        obj.dd.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).toggleClass('active');
        });
        obj.opts.on('click', function () {
            var opt = $(this);
            obj.val = opt.text();
            obj.index = opt.index();
            obj.placeholder.text(obj.val);
            opt.siblings().removeClass('selected');
            opt.filter(':contains("' + obj.val + '")').addClass('selected');
        }).change();
    },
    getValue: function () {
        return this.val;
    },
    getIndex: function () {
        return this.index;
    }
};

$(function () {
    const categoriesDropdown = new DropDown($('#categories'));
    const citiesDropdown = new DropDown($('#cities'));
    $(document).click(function () {
        $('.wrap-drop').removeClass('active');
    });
});

// --------------Career Description Page------------------------------------------------
$('.single-page').parent().addClass('singlePage-banner');

// Animation on scoll left and right Movement
var upper = $('#upper, #bottom');
var lower = $('#lower');

var startPosition = (upper.position() != undefined) ? upper.position().left : 0;
var speed = 14;

$(window).scroll(function () {
    var st = $(this).scrollTop();
    var newPos = (st * (speed/100)) + startPosition;
    upper.css({
        'left': newPos
    });
    lower.css({
        'right': newPos
    });
});

// News toggle on home page
const newsBlockClosed = document.cookie.includes("newsBlockClosed=true");

if (!newsBlockClosed) {
    $("#latest-news").addClass("show");
}

$("#news-close-button").click(function() {
    $("#latest-news").removeClass("show");
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);
    const expires = expirationDate.toUTCString();
    document.cookie = `newsBlockClosed=true; expires=${expires}; path=/`;
});

